import React, { Component } from 'react';
import { Modal, ModalBody, CloseButton, } from 'react-bootstrap';
import CookieConsent from "react-cookie-consent";
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import API from "services/api";
import Tutorial from "components/Tutorial";
import 'animate.css';
import { fireLinkeeCompletion, fireBuyClick, } from "services/analytics";

import styles from "components/Home.module.scss";
import { Link } from "components/Link";
import { LinkTable } from "components/LinkTable";
import { Stat } from "components/Stat";
import CountdownTimer from "components/Countdown";

import content from "constants/content";

import TempLinkeeBox from "images/temp-linkee-box-2.png";
import LinkeeBox from "images/linkeeBox.png";
import QuestionBacker from "images/questions.png";
import BackgroundMobile from "images/backgroundTall.png";
import Background from "images/backgroundWide.png";
import Title from "images/title.png";
import QuestionColorBacker from "images/question-color-backer.png";
import StatsIcon from "images/statsNew.png";
import HelpIcon from "images/helpNew.png";
import LinkeeModal from "images/linkeeModal.png";
import Backspace from "images/backspace.png";
import BPGLogo from "images/BPG-logo.png";
import redCircle from "images/red-circle.png";
import greenCircle from "images/green-circle.png";
import folders from "images/folders.png";
import emptyCircle from "images/empty-circle.png";
import clueCircle from "images/clue-circle.png";
import share from "images/share.png";
import stripOne from "images/Strip1.png";
import stripTwo from "images/Strip2.png";
import stripThree from "images/Strip3.png";
import stripFour from "images/Strip4.png";

import "styles/scss/main.scss";

const dummyLinkee = {
    id: 1,
    date: "31/03/2022",
    links: [
        {
            index: 0,
            question: "\"You're going to need a bigger boat\" is a quote from which '70s blockbuster starring Roy Scheider?",
            answer: "Jaws"
        },
        {
            index: 1,
            question: "If someone speaks recklessly or impulsively, they're said to shoot from the what?",
            answer: "Hip"
        },
        {
            index: 2,
            question: "What is the measurement from the center to the outer edge of a circle called?",
            answer: "Radius"
        },
        {
            index: 3,
            question: "Which Indiana Jones film was shot in 2008? The Kingdom of the Crystal what?",
            answer: "Skull"
        }
    ],
    clue: "A jellyfish doesn’t have any of these",
    linkee: "Bones"
};

const keyboardMap = {
    row1: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    row2: ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    row3: ["Z", "X", "C", "V", "B", "N", "M", "←"]
}
let wordsRow = 0;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            currentLinkee: dummyLinkee,
            linkeeSplit: [],
            LinkeeSplitWords: [],
            currentAnswer: "",
            currentSplitAnswer: [],
            linkeeIndex: -1,
            revealAll: false,
            isOpenTutorial: false,
            isOpenSummary: false,
            playedToday: false,
            revealedClue: false,
            playerStats: {},
            showIncorrect: false,
            copiedSuccess: "Share",
            buyTheGame: "Buy the game",
            shareText: "",
            validation: null,
            validationText: "",
            isMobile: false,
            width: 0,
            height: 0,
            hideMain: false,
            hideKeyboard: true,
            buyNowURL: "",
            countryCode: "",
        };

        this.revealQuestion = this.revealQuestion.bind(this);
        this.showStatsButton = this.showStats.bind(this);
        this.showTutorialButton = this.showTutorial.bind(this);

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    async componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        this.retreiveLocalStorage();
        document.addEventListener("keydown", this.keydownHandler);
        //
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        API.insertNewPlay({});
        API.getCountryCode().then((res) => {
            let buyUrl = "";
            if (res == "GB") {
                buyUrl = "https://www.amazon.co.uk/Linkee-Game-Ideal-updated-version/dp/B00IN41WRU/";
            } else {
                buyUrl = "https://linktr.ee/BuyLinkee";
            }
            this.setState({ buyNowURL: buyUrl, countryCode: res, });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    retreiveLocalStorage() {
        const todaysDate = this.getTodaysDate();

        //if localhost and testing >
        var currentUrl = window.location.hostname;
        if (currentUrl == "localhost") {
            // RESET GAME CODE - THESE 4 LINES SHOULD BE COMMENTED OUT IN BUILDS
            localStorage.setItem("linkeeIndex", "0");
            localStorage.setItem("HasPlayedBefore", null);
            localStorage.setItem("LastPlayedDate", null);
            localStorage.setItem("LastVisitedDate", null);
        }

        let hasPlayedBefore = localStorage.getItem("HasPlayedBefore");
        let lastPlayedDate = localStorage.getItem("LastPlayedDate");
        let lastVisitedDate = localStorage.getItem("LastVisitedDate");

        let hasPlayedToday = todaysDate == lastPlayedDate;

        const linkee = this.getLinkee(todaysDate, hasPlayedToday);

        if (!hasPlayedBefore) {
            this.setState({ isOpenTutorial: true, });
        }

        if (todaysDate == lastVisitedDate) {
            let linkeeIndex = localStorage.getItem("linkeeIndex");
            linkeeIndex = parseInt(linkeeIndex);
            this.setState({ linkeeIndex });
        } else {
            localStorage.setItem("linkeeIndex", this.state.linkeeIndex.toString());
        }

        if (hasPlayedToday) {
            let linkeeIndex = localStorage.getItem("linkeeIndex");
            linkeeIndex = parseInt(linkeeIndex);

            this.setState({ linkeeIndex }, () => {
                this.getAndShowStats(linkee);
                this.doHasPlayedToday();

            });
        } else {
            this.revealQuestion();
        }

        localStorage.setItem("LastVisitedDate", this.getTodaysDate());
    }

    doHasPlayedToday() {

        let validationToday = localStorage.getItem("ValidationToday");
        if (validationToday) {
            this.doValidation(validationToday == "correct" ? true : false);
        } else {
            this.doValidation(true);
        }
        this.showStats();
    }

    getAndShowStats(linkee = null) {
        this.setState({ playedToday: true, revealAll: true, isOpenSummary: true, });
        if (linkee != null) {
            this.setState({ currentAnswer: linkee.linkee.toUpperCase(), });
        }
    }

    getTodaysDate() {
        //return this.getTomorrowsDate(); // testing
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    getTomorrowsDate() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const yyyy = tomorrow.getFullYear();
        let mm = tomorrow.getMonth() + 1; // Months start at 0!
        let dd = tomorrow.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    revealQuestion() {
        const newIndex = this.state.linkeeIndex + 1;
        this.setState({ linkeeIndex: newIndex });
        localStorage.setItem("linkeeIndex", newIndex.toString());
    }

    gameOver() {
        // gameOver
        localStorage.setItem("ValidationToday", "incorrect");
        localStorage.setItem("HasPlayedBefore", "true");
        localStorage.setItem("LastPlayedDate", this.getTodaysDate());

        let totalPlayed = localStorage.getItem("TotalPlayed");
        if (totalPlayed == null) {
            totalPlayed = 0;
        }
        totalPlayed = Number(totalPlayed);
        totalPlayed += 1;
        localStorage.setItem("TotalPlayed", `${totalPlayed}`);

        this.getAndShowStats();
        this.showStats();
        this.doValidation(false);
        API.updatePlayFinished({
            correct: false,
            linkeeIndex: this.state.linkeeIndex,
        });
        fireLinkeeCompletion({ correct: false, locale: this.state.countryCode, });
    }

    keydownHandler = (event) => {
        if (!this.state.playedToday && this.state.linkeeIndex > -1 && !this.state.hideKeyboard) {
            let currentAnswer = this.state.currentAnswer;
            if (event.keyCode >= 65 && event.keyCode <= 90 || event.keyCode >= 48 && event.keyCode <= 57) {
                if (currentAnswer.length != this.state.currentLinkee.linkee.length) {
                    this.addLetterToAnswer(event.key);
                }
                this.setState({ showIncorrect: false });
            } else if (event.keyCode == 8) {
                this.removeLetterFromAnswer();
                this.setState({ showIncorrect: false });
            } else if (event.keyCode == 13) {
                this.submitAnswer();
            }

        }
    }

    handleInput = (key) => {
        if (!this.state.playedToday && this.state.linkeeIndex > -1) {
            let currentAnswer = this.state.currentAnswer;
            if (key == "←") {
                this.removeLetterFromAnswer();
                this.setState({ showIncorrect: false });
            }
            else if (key == "SKIP") {
                if (this.state.linkeeIndex < 3) {
                    this.revealQuestion();
                }
            }
            else if (key == "SUBMIT") {
                this.submitAnswer();
            } else {
                if (currentAnswer.length != this.state.currentLinkee.linkee.length) {
                    this.addLetterToAnswer(key);
                }
                this.setState({ showIncorrect: false });
            }
        }
    }

    handleTextAreaChange = (event) => {
        this.setState({ shareText: event.target.value }, () => {
        });
    }

    clickBuyNow = (e) => {
        e.preventDefault();

        API.countNewClick("BuyTheGame");
        this.openInNewTab(this.state.buyNowURL);
        fireBuyClick({ locale: this.state.countryCode, });
    }

    openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    shareResult = () => {
        API.countNewShare();

        let shareText = "";
        shareText += "Linkee Daily";
        shareText += ` #${this.state.currentLinkee.id}`;

        if (this.state.validation == true) {
            if (this.state.linkeeIndex === 0) {
                //shareText += "I solved todays LINKEE with only 1 link! See if you can too!";
                shareText += "\n\n 🟢⚪⚪⚪⚪"
            } else if (this.state.linkeeIndex === 1) {
                //shareText += "I solved todays LINKEE with just 2 links! See if you can beat me!";
                shareText += "\n\n 🔴🟢⚪⚪⚪"
            } else if (this.state.linkeeIndex === 2) {
                //shareText += "I solved todays LINKEE with 3 links! See if you can solve it sooner!";
                shareText += "\n\n 🔴🔴🟢⚪⚪"
            } else if (this.state.linkeeIndex === 3) {
                //shareText += "I solved todays LINKEE using all 4 links! Can you solve it quicker?";
                shareText += "\n\n 🔴🔴🔴🟢⚪"
            } else {
                //shareText += "I solved todays LINKEE using all 4 links & the clue! Can you do it without using the clue?";
                shareText += "\n\n 🔴🔴🔴⚫🟢"
            }

        } else {
            //shareText += "I couldn't solve todays LINKEE... can you?";
            shareText += "\n\n 🔴🔴🔴🔴⚫"

        }

        if (navigator.share) {
            navigator.share({
                title: "Linkee Daily",
                text: shareText,
                url: `${window.location.href}`
            }).then(() => {
                console.log("Thanks for sharing");
            });
        } else {

        }

        let copyText = shareText + `\n\n ${window.location.href}`;

        this.setState({ shareText: copyText, })
        navigator.clipboard.writeText(copyText);

        this.setState({ copiedSuccess: "Copied!" })
    }

    addSpaceToAnswer() {
    }

    addLetterToAnswer(letter) {
        let currentAnswer = this.state.currentAnswer;

        let currentLinkee = this.state.currentLinkee.linkee;

        let indices = [];
        for (let i = 0; i < currentLinkee.length; i++) {
            if (currentLinkee[i] === " ") indices.push(i);
        }

        for (let j = 0; j < indices.length; j++) {
            if (currentAnswer.length === (indices[j])) {
                currentAnswer += " ";
            }
        }

        if (typeof letter == "object") {
            for (let i = 0; i < letter.length; i++) {
                currentAnswer += letter[i].toUpperCase();
            }
        } else {

            currentAnswer += letter.toUpperCase();
        }

        let splitAnswer = this.state.currentSplitAnswer;

        splitAnswer = splitAnswer.map((x) => []);

        let splitLinkee = this.state.LinkeeSplitWords;

        let letters = currentAnswer.split("");
        letters = letters.filter((x) => x != " ");
        let letterIndex = 0;

        for (let x = 0; x < splitLinkee.length; x++) {
            let currWord = splitAnswer[x];
            let actualWord = splitLinkee[x];

            if (currWord.length == actualWord.length) {

            } else {
                for (let y = 0; y < actualWord.length; y++) {
                    if (letters[letterIndex]) {
                        splitAnswer[x][y] = letters[letterIndex];
                        letterIndex++;
                    }
                }
            }
        }

        this.setState({
            currentAnswer,
            currentSplitAnswer: splitAnswer
        });
    }

    removeLetterFromAnswer() {
        let currentAnswer = this.state.currentAnswer;
        const lastChar = currentAnswer.charAt(currentAnswer.length - 1);
        if (lastChar === " ") {
            currentAnswer = currentAnswer.slice(0, -2);
        } else {
            currentAnswer = currentAnswer.slice(0, -1);
        }

        let newAnswer = this.state.currentSplitAnswer;
        for (let x = newAnswer.length - 1; x >= 0; x--) {
            let ansWord = newAnswer[x];

            if (ansWord.length > 0) {
                newAnswer[x].pop();

                break;
            }
        }

        this.setState({ currentAnswer, currentSplitAnswer: newAnswer });
    }

    getLinkee(date, hasPlayedBefore = false) {
        // add in stuff to get acutal daily linkee
        const newLinkee = this.getLinkeeByDate(date);
        let newSplitLinkee = [];
        let arrayWords = newLinkee.linkee.split(" ");

        let arraySplit = [];
        let answerArray = [];

        for (let j = 0; j < arrayWords.length; j++) {
            let currWord = arrayWords[j].split("");
            arraySplit.push(currWord);
            answerArray.push([]);
        }

        for (let i = 0; i < newLinkee.linkee.length; i++) {
            newSplitLinkee.push(newLinkee.linkee[i]);
        }

        this.setState({ currentLinkee: newLinkee, linkeeSplit: newSplitLinkee, LinkeeSplitWords: arraySplit, currentSplitAnswer: answerArray }, () => {
            if (hasPlayedBefore) {
                let split = newLinkee.linkee.split("");
                this.addLetterToAnswer(split);
            }
        });
        return newLinkee;
    }

    getLinkeeByDate(date) {
        let todaysLinkee;
        content.forEach((x) => {
            if (x.date == date) {
                todaysLinkee = x;
            }
        });

        if (!todaysLinkee) {
            const startDate = this.convertToDate(content[0].date);
            const todaysDate = this.convertToDate(date);

            const diffTime = Math.abs(todaysDate - startDate);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


            todaysLinkee = content[diffDays % content.length];
        }
        return todaysLinkee;
    }

    convertToDate(dateString) {
        //  Convert a "dd/MM/yyyy" string into a Date object
        let d = dateString.split("/");
        let dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
        return dat;
    }

    submitAnswer() {
        if (!this.state.playedToday) {
            const currentAnswer = this.state.currentAnswer.toLowerCase();
            const actualAnswer = this.state.currentLinkee.linkee.toLowerCase();
            if (currentAnswer.length == actualAnswer.length) {
                if (currentAnswer === actualAnswer) {
                    localStorage.setItem("HasPlayedBefore", "true");
                    localStorage.setItem("ValidationToday", "correct");
                    localStorage.setItem("LastPlayedDate", this.getTodaysDate());
                    this.getAndShowStats();
                    this.updateStats();
                    this.showStats();
                    this.doValidation(true);
                    // API
                    API.updatePlayFinished({
                        correct: true,
                        linkeeIndex: this.state.linkeeIndex,
                    });
                    fireLinkeeCompletion({ correct: true, locale: this.state.countryCode, });
                } else {
                    //alert("oof");
                    if (this.state.linkeeIndex === 4) {
                        this.gameOver();

                    } else if (this.state.linkeeIndex === 3) {
                        this.tryReveal();
                        this.clearAnswer();
                        this.setState({ showIncorrect: true, validation: false, validationText: "Here's the clue to help! You only get 1 more attempt to guess correctly!" });
                    } else {
                        this.revealQuestion();
                        this.clearAnswer();
                    }
                }
            } else {
                this.animateCSS("#answerContainer", "shakeX");
            }
        }
    }

    clearAnswer() {
        this.setState({ showIncorrect: true });
        var that = this.state.currentAnswer;
        var thats = this;
        setTimeout(function () {
            for (let i = 0; i < that.length; i++) {
                thats.removeLetterFromAnswer();
            }
            thats.setState({ showIncorrect: false, currentAnswer: "", linkeeSplit: [] });
        }, 1000);
    }

    doValidation(isCorrect = true) {

        let validationText = "";

        if (isCorrect) {
            if (this.state.linkeeIndex === 0) {
                validationText += '<img src="' + greenCircle + '"/>' + '<img src="' + emptyCircle + '"/>' + '<img src="' + emptyCircle + '"/>' + '<img src="' + emptyCircle + '"/>' + '<img src="' + emptyCircle + '"/>'
            } else if (this.state.linkeeIndex === 1) {
                validationText += '<img src="' + redCircle + '"/>' + '<img src="' + greenCircle + '"/>' + '<img src="' + emptyCircle + '"/>' + '<img src="' + emptyCircle + '"/>' + '<img src="' + emptyCircle + '"/>'
            } else if (this.state.linkeeIndex === 2) {
                validationText += '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + greenCircle + '"/>' + '<img src="' + emptyCircle + '"/>' + '<img src="' + emptyCircle + '"/>'
            } else if (this.state.linkeeIndex === 3) {
                validationText += '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + greenCircle + '"/>' + '<img src="' + emptyCircle + '"/>'
            } else {
                validationText += '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + greenCircle + '"/>' + '<img src="' + clueCircle + '"/>'
            }

        } else {
            //validationText = "Tough luck! Come back and try tomorrows LINKEE!"
            validationText += '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + redCircle + '"/>' + '<img src="' + clueCircle + '"/>'
        }

        this.setState({ showIncorrect: false, validation: isCorrect, validationText });
    }

    updateStats() {
        let answeredOnFirst = localStorage.getItem("AnsweredFirstLink");
        let answeredOnSecond = localStorage.getItem("AnsweredSecondLink");
        let answeredOnThird = localStorage.getItem("AnsweredThirdLink");
        let answeredOnFourth = localStorage.getItem("AnsweredFourthLink");
        let answeredOnClue = localStorage.getItem("AnsweredClue");
        let totalPlayed = localStorage.getItem("TotalPlayed");

        if (this.state.linkeeIndex === 0) {
            if (answeredOnFirst == null) {
                answeredOnFirst = 0;
            }
            answeredOnFirst = Number(answeredOnFirst);
            answeredOnFirst += 1;
            localStorage.setItem("AnsweredFirstLink", `${answeredOnFirst}`);
        } else if (this.state.linkeeIndex === 1) {
            if (answeredOnSecond == null) {
                answeredOnSecond = 0;
            }
            answeredOnSecond = Number(answeredOnSecond);
            answeredOnSecond += 1;
            localStorage.setItem("AnsweredSecondLink", `${answeredOnSecond}`);
        } else if (this.state.linkeeIndex === 2) {
            if (answeredOnThird == null) {
                answeredOnThird = 0;
            }
            answeredOnThird = Number(answeredOnThird);
            answeredOnThird += 1;
            localStorage.setItem("AnsweredThirdLink", `${answeredOnThird}`);
        } else if (this.state.linkeeIndex === 3) {
            if (answeredOnFourth == null) {
                answeredOnFourth = 0;
            }
            answeredOnFourth = Number(answeredOnFourth);
            answeredOnFourth += 1;
            localStorage.setItem("AnsweredFourthLink", `${answeredOnFourth}`);
        } else {
            if (answeredOnClue == null) {
                answeredOnClue = 0;
            }
            answeredOnClue = Number(answeredOnClue);
            answeredOnClue += 1;
            localStorage.setItem("AnsweredClue", `${answeredOnClue}`);
        }

        if (totalPlayed == null) {
            totalPlayed = 0;
        }
        totalPlayed = Number(totalPlayed);
        totalPlayed += 1;
        localStorage.setItem("TotalPlayed", `${totalPlayed}`);


    }

    showStats = () => {
        let answeredOnFirst = localStorage.getItem("AnsweredFirstLink") || 0;
        let answeredOnSecond = localStorage.getItem("AnsweredSecondLink") || 0;
        let answeredOnThird = localStorage.getItem("AnsweredThirdLink") || 0;
        let answeredOnFourth = localStorage.getItem("AnsweredFourthLink") || 0;
        let answeredOnClue = localStorage.getItem("AnsweredClue") || 0;
        let totalPlayed = localStorage.getItem("TotalPlayed") || 0;

        let lost = Number(totalPlayed) - (Number(answeredOnFirst) + Number(answeredOnSecond) + Number(answeredOnThird) + Number(answeredOnFourth) + Number(answeredOnClue));
        let win = Number(totalPlayed) - Number(lost);

        let percent = 0;
        if (totalPlayed != 0) {
            percent = ((100 * win) / totalPlayed).toFixed(0);
        }

        this.setState({
            playerStats: {
                firstLink: answeredOnFirst || 0,
                secondLink: answeredOnSecond || 0,
                thirdLink: answeredOnThird || 0,
                fourthLink: answeredOnFourth || 0,
                clue: answeredOnClue || 0,
                total: totalPlayed || 0,
                lost: lost,
                percentage: percent
            },
            isOpenSummary: true
        });
    }

    tryReveal = () => {
        let canReveal = this.state.linkeeIndex >= 3 ? true : false;
        if (canReveal && !this.state.revealedClue && !this.state.revealAll) {
            this.setState({ revealedClue: true });
            if (this.state.linkeeIndex < 4) {
                this.revealQuestion();
            }
        } else {

        }
    }

    showTutorial = () => {
        API.countNewClick("Tutorial");
        this.setState({ isOpenTutorial: true })
    }

    closeTutorial = () => {
        this.setState({
            isOpenTutorial: false,
            hideMain: false,
            //hideKeyboard: false
        })
    }

    currentStep = (step) => {
        if (step == 7) {
            this.setState({
                hideMain: true
            })
        }
        else {
            this.setState({
                hideMain: false,
            })
        }
    }

    handleCallback = (childData) => {
        this.setState({
            //hideKeyboard: true,
            hideMain: false
        })
    }

    resize = () => {
        const mediaQuery = window.matchMedia("(min-width: 991px)");

        if (mediaQuery.matches) {
            this.setState({ isMobile: false });
        } else {
            this.setState({ isMobile: true });
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            if (node) {
                node.classList.add(`${prefix}animated`, animationName);

                // When the animation ends, we clean the classes and resolve the Promise
                function handleAnimationEnd(event) {
                    event.stopPropagation();
                    node.classList.remove(`${prefix}animated`, animationName);
                    resolve('Animation ended');
                }

                node.addEventListener('animationend', handleAnimationEnd, { once: true });
            }
        });

    //calculateTimeLeft = () => {
    //    let today = new Date();

    //    let tomorrow = new Date();
    //    tomorrow.setDate(tomorrow.getDate() + 1);
    //    tomorrow.setHours(0, 0, 0, 0);

    //    const difference = tomorrow - today;
    //    let timeLeft = {};

    //    if (difference > 0) {
    //        timeLeft = {
    //            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    //            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    //            minutes: Math.floor((difference / 1000 / 60) % 60),
    //            seconds: Math.floor((difference / 1000) % 60)
    //        };
    //    }

    //    return timeLeft;
    //}

    renderRows() {
        let finalArr = [], columns = [];
        var arrayLinkeeWords = this.state.LinkeeSplitWords;

        arrayLinkeeWords.map((y, Yindex2) => {
            wordsRow += y.length;
            if (wordsRow > 9 & wordsRow != 0) {
                wordsRow = y.length;
                finalArr.push(<div className={styles.answerRow}>{columns}</div>);
                columns = [];
            }

            y.map((x, Xindex) => {
                const lastIndex = y.length - 1;
                const lastMap = arrayLinkeeWords.length - 1;
                columns.push(<div className={`${styles.answerBox} ${this.state.isMobile && styles.smallerAns} ${Xindex === lastIndex && Yindex2 !== lastMap ? styles.space : ""} ${this.state.revealAll ? this.state.validation == true ? styles.correct : styles.incorrect : this.state.showIncorrect ? styles.incorrect : ""}`}>
                    <div className={`${styles.answerText} ${this.state.isMobile && styles.smallerAnsText}`}>{this.state.currentSplitAnswer[Yindex2][Xindex]}</div>
                </div>)
            })
        })
        finalArr.push(<div className={styles.answerRow}>{columns}</div>);

        return finalArr;
    }

    render() {
        //const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
        return (
            <div className={`mainBackground ${styles.background}`}>
                <div className={styles.header}>
                    <div className={styles.menuLeft}>
                        <img src={HelpIcon} className={styles.icon} onClick={this.showTutorialButton} />
                    </div>
                    <div className={styles.menuCenter}>
                        <img src={Title} className={styles.headerText} />
                    </div>
                    <div className={styles.menuRight}>
                        <img src={StatsIcon} className={styles.icon} onClick={this.showStatsButton} />
                    </div>
                </div>
                <div className={styles.mainContainer} style={{ height: this.state.height }}>
                    {!this.state.hideMain &&
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    {
                                        this.state.currentLinkee != null &&
                                        <React.Fragment>
                                            <table className={`table ${styles.linksBox} ${styles.linksTable}`} cellSpacing={0}>
                                                <tbody>
                                                    <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                    <LinkTable linkData={this.state.currentLinkee.links[0]} linkeeIndex={this.state.linkeeIndex} revealAll={this.state.revealAll} revealQuestion={this.revealQuestion} />
                                                    <tr className={`tableStrip tableStrip1`}>
                                                        <th></th>
                                                        <td></td>
                                                        {/*<img src={stripOne} />*/}
                                                    </tr>
                                                    <LinkTable linkData={this.state.currentLinkee.links[1]} linkeeIndex={this.state.linkeeIndex} revealAll={this.state.revealAll} revealQuestion={this.revealQuestion} />
                                                    <tr className={`tableStrip tableStrip2`}>
                                                        <th></th>
                                                        <td></td>
                                                        {/*<img src={stripTwo} />*/}
                                                    </tr>
                                                    <LinkTable linkData={this.state.currentLinkee.links[2]} linkeeIndex={this.state.linkeeIndex} revealAll={this.state.revealAll} revealQuestion={this.revealQuestion} />
                                                    <tr className={`tableStrip tableStrip3`}>
                                                        <th></th>
                                                        <td></td>
                                                        {/*<img src={stripThree} />*/}
                                                    </tr>
                                                    <LinkTable linkData={this.state.currentLinkee.links[3]} linkeeIndex={this.state.linkeeIndex} revealAll={this.state.revealAll} revealQuestion={this.revealQuestion} />
                                                    <tr className={`tableStripLast tableStrip4`}>
                                                        <th></th>
                                                        <td></td>
                                                        {/*    <img src={stripFour} />*/}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className={`${styles.clueBox}`}>
                                                <div className={`${styles.showLinkButton} ${this.state.linkeeIndex >= 3 ? this.state.revealAll ? styles.disabled : "" : styles.disabled}`} onClick={this.tryReveal}>
                                                    <div className={styles.buttonText}>CLUE?</div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                            </div>

                            {this.state.currentLinkee != null &&
                                <div className={styles.answerSection} >
                                    <div id="answerContainer" className={styles.answerContainer}>
                                        <div className={styles.AnswerTitle}>What links the answers?</div>
                                        {this.renderRows()}
                                    </div>
                                </div>}

                            <div className={`${styles.keyboardBox} ${this.state.hideKeyboard && styles.hidden}`} style={this.state.playedToday ? { display: 'none' } : {}}>
                                <div className={styles.row}>
                                    {
                                        keyboardMap.row1.map((x, index) => {
                                            return <div key={index + x + "_parent"} className={styles.key} onClick={() => this.handleInput(x)}>
                                                <div key={index + x} className={styles.letter}>{x}</div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className={styles.spacer}></div>
                                <div className={styles.row}>
                                    {
                                        keyboardMap.row2.map((x, index) => {
                                            return <div key={index + x + "_parent"} className={styles.key} onClick={() => this.handleInput(x)}>
                                                <div key={index + x} className={styles.letter}>{x}</div>
                                            </div>
                                        })
                                    }
                                </div>
                                <div className={styles.spacer}></div>
                                <div className={styles.row}>
                                    {
                                        keyboardMap.row3.map((x, index) => {
                                            return <div key={index + x + "_parent"} className={`${styles.key} ${x == "←" && styles.skipKey} ${x == "←" && styles.skipKey}`} onClick={() => this.handleInput(x)}>
                                                <div key={index + x} className={`${x == "←" ? styles.backspaceKey : styles.letter}`}>
                                                    {x == "←" ? <img key={index + x + "_img"} src={Backspace} className={styles.backspaceImg} /> : x}
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>

                            <div className="row justify-content-center" style={this.state.hideKeyboard ? { marginBottom: "auto", } : {}}>
                                {
                                    this.state.playedToday ?
                                        <div className="col-5 ms-2 me-2 p-0">
                                            <button className={`${styles.submitKey} ${styles.bottomButton}`} onClick={() => this.showStats()}>RESULTS</button>
                                        </div>
                                        :
                                        <React.Fragment>
                                            <div className="col-5 ms-2 me-2 p-0">
                                                <button className={`${styles.nextQuestionKey} ${styles.bottomButton}`} onClick={() => this.state.linkeeIndex < 3 ? this.revealQuestion() : this.state.linkeeIndex == 3 ? this.tryReveal() : this.gameOver()}>
                                                    <span className={`${styles.noIdea}`}>No idea?</span>
                                                    {
                                                        this.state.linkeeIndex >= 4 ?
                                                            "GIVE UP"
                                                            :
                                                            this.state.linkeeIndex == 3 ?
                                                                "REVEAL CLUE"
                                                                :
                                                                "NEXT QUESTION"
                                                    }
                                                </button>
                                            </div>
                                            <div className="col-5 ms-2 me-2 p-0">
                                                <button type="submit"
                                                    className={`${styles.submitKey} ${styles.bottomButton}`}
                                                    onClick={this.state.hideKeyboard ? () => this.setState({ hideKeyboard: false, }) : () => this.submitAnswer()}>
                                                    {
                                                        this.state.hideKeyboard ?
                                                            <React.Fragment>
                                                                <span className={`${styles.noIdea}`}>I Know</span>
                                                                <span>THE LINK</span>
                                                                </React.Fragment>
                                                            :
                                                            "SUBMIT"
                                                    }
                                                </button>
                                            </div>
                                        </React.Fragment>
                                }
                            </div>
                        </div>
                    }
                </div>

                {/*Walkthrough tutorial modal*/}
                <Modal id="ModalTutorial" backdrop="static" aria-labelledby="contained-modal-title-vcenter" size="lg" centered className={`${styles.tutorialModal} ${styles.modalOpen}`} show={this.state.isOpenTutorial} onHide={() => this.setState({ isOpenTutorial: false })}>
                    <Modal.Header className={styles.closeButton} closeButton></Modal.Header>
                    <ModalBody className={styles.modalOpenBody}>
                        <StepsProvider>
                            <Tutorial close={this.closeTutorial} height={this.state.height} step={this.currentStep} hide={this.handleCallback} />
                        </StepsProvider>
                    </ModalBody>
                </Modal>

                {/*Statistics modal*/}
                <Modal id="ModalSummary" keyboard={false} aria-labelledby="contained-modal-title-vcenter" size="lg" centered className={`${styles.statsModal} ${styles.modalOpen}`} show={this.state.isOpenSummary} onHide={() => this.setState({ isOpenSummary: false })}>
                    <Modal.Header className={`${styles.headerSummary} headerSummary`} closeButton>
                        <img src={LinkeeModal} className={styles.imageLinkee} />
                    </Modal.Header>
                    <ModalBody className={`${styles.modalBody} ${styles.modalOpenBody}`} style={{ height: this.state.height }}>
                        <div className={styles.gameBox}>
                            <div className={`${styles.validationBox} ${this.state.revealAll ? "" : styles.disabled}`}>
                                {
                                    this.state.validation == true ?
                                        <p className={styles.guessCountText}>You guessed the Linkee after:</p>
                                        :
                                        <p className={styles.guessCountText}>You failed to guess the Linkee:</p>
                                }
                            </div>
                            <div className={`${styles.validationBox} ${this.state.revealAll ? "" : styles.disabled}`}>
                                {
                                    this.state.validation == true ?
                                        <h3 className={styles.questionCountText}>
                                            {this.state.linkeeIndex == 0 ? this.state.linkeeIndex + 1 + " question" : this.state.linkeeIndex >= 4 ? 4 + " questions + clue" : this.state.linkeeIndex + 1 + " questions"}
                                        </h3>
                                        :
                                        <div className={styles.title}>
                                            GAME OVER!
                                        </div>
                                }
                                <div className={`${styles.answer} ${this.state.validation != true && styles.red}`}>
                                    {
                                        this.state.validation != true && this.state.currentLinkee.linkee.toUpperCase()
                                    }
                                </div>
                            </div>
                            <div className={`${styles.validationBox} ${this.state.revealAll ? "" : styles.disabled}`}>
                                <div className={styles.feedback}>
                                    <div className={styles.feedbackQCount} dangerouslySetInnerHTML={{ __html: this.state.validationText }} />
                                    <img className={styles.copyButton} src={folders} onClick={this.shareResult} />
                                </div>
                            </div>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <div className={`${styles.statsBox} ${styles.absBoxStats}`}>
                                        <div className={styles.absTable}>
                                            <header className={styles.title}>STATS</header>
                                            <table className={`table ${styles.tableStats} ${styles.linksTable}`}>
                                                <tbody>
                                                    <tr className={styles.rowCount}>
                                                        <td className={styles.rowTD}>{this.state.playerStats.total}</td>
                                                        <td className={styles.rowTD}>{this.state.playerStats.lost}</td>
                                                        <td className={styles.rowTD}>{this.state.playerStats.percentage}</td>
                                                    </tr>
                                                    <tr className={`${styles.rowCount} mb-2`}>
                                                        <td className={styles.rowTD}>Played</td>
                                                        <td className={styles.rowTD}>Lost</td>
                                                        <td className={styles.rowTD}>Win %</td>
                                                    </tr>
                                                    <Stat title="1 Link" totalPlayed={this.state.playerStats.total - this.state.playerStats.lost} statPlayed={this.state.playerStats.firstLink} clue={false} color="red" />
                                                    <Stat title="2 Links" totalPlayed={this.state.playerStats.total - this.state.playerStats.lost} statPlayed={this.state.playerStats.secondLink} clue={false} color="red" />
                                                    <Stat title="3 Links" totalPlayed={this.state.playerStats.total - this.state.playerStats.lost} statPlayed={this.state.playerStats.thirdLink} clue={false} color="red" />
                                                    <Stat title="4 Links" totalPlayed={this.state.playerStats.total - this.state.playerStats.lost} statPlayed={this.state.playerStats.fourthLink} clue={false} color="red" />
                                                    <Stat title="Clue" totalPlayed={this.state.playerStats.total - this.state.playerStats.lost} statPlayed={this.state.playerStats.clue} clue={true} color="red" />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.shareSection} ${this.state.revealAll ? "" : styles.disabled}`}>
                                <CountdownTimer />
                            </div>
                            <div className={`${styles.shareSection} ${styles.shareSection2} ${this.state.revealAll ? "" : styles.disabled}`}>
                                <div className={`${styles.gameButton} ${this.state.revealAll ? "" : styles.disabled}`} onClick={this.clickBuyNow} >
                                    <div className={styles.buttonText} >{this.state.buyTheGame}</div>
                                </div>
                                <div className={`${styles.shareButton} ${this.state.revealAll ? "" : styles.disabled}`} onClick={this.shareResult} >
                                    <div className={styles.buttonText} >{this.state.copiedSuccess} <img className={styles.buttonImg} src={share} /></div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/*Clue modal*/}
                <Modal id="ModalClue" aria-labelledby="contained-modal-title-vcenter" size="lg" centered className={`${styles.modalOpen} ${styles.clueModal}`} show={this.state.revealedClue} onHide={() => this.setState({ revealedClue: false })}>
                    <Modal.Header className={`${styles.closeButton} ${styles.clue}`} onClick={() => this.setState({ revealedClue: false, })}>
                        <CloseButton variant="white" />
                    </Modal.Header>
                    <ModalBody className={styles.modalOpenBody}>
                        <div className={`${styles.validationBox} ${this.state.validation == false ? "" : styles.disabled}`}>
                            <div className={styles.title}>{this.state.validation == true ? "CORRECT!" : "WRONG!"}</div>
                            <div className={styles.feedback}>{this.state.validationText}</div>
                        </div>
                        <div className={styles.clue}>
                            <div className={styles.clueModal}>CLUE</div>
                            <div className={styles.clueText}>{this.state.currentLinkee.clue}</div>
                        </div>
                    </ModalBody>
                </Modal>
                {/*Cookie consent*/}
                <CookieConsent
                    location="bottom"
                    buttonText="I Accept!"
                    cookieName="MyLinkeeCookie"
                    style={{ background: "#3F4144" }}
                    buttonStyle={{ background: "#E75A56", color: "#F0F0F0", fontSize: "13px" }}
                    expires={150}
                >
                    This website uses cookies to enhance the user experience.{" "}
                </CookieConsent>
            </div>
        );
    }
}

import React, { Component } from 'react';

import styles from "components/GameDemo.module.scss";
import Backspace from "images/backspace.png";
//
import one from "images/1.png";
import two from "images/2.png";
import three from "images/3.png";
import four from "images/4.png";
import bubbleArrow from "images/bubble-arrow.png";
import LinkeeBox from "images/linkeeBox.png";

import "styles/scss/main.scss";

const dummyLinkee = {
    id: 1,
    date: "10/06/2022",
    links: [
        {
            index: 0,
            question: "\"What are Provolone, Swiss and Mozzarella?",
            answer: "Cheese"
        },
        {
            index: 1,
            question: "A southern breakfast is fried chicken and what?",
            answer: "Waffle"
        },
        {
            index: 2,
            question: "What nationality was the artist Claude Monet?",
            answer: "French"
        },
        {
            index: 3,
            question: "Putting rollers in your hair makes it go what?",
            answer: "Curly"
        }
    ],
    clue: "There's only one real McCains",
    linkee: "Fries"
};

const keyboardMap = {
    row1: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    row2: ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    row3: ["Z", "X", "C", "V", "B", "N", "M", "←"]
}

let wordsRow2 = 0;

export class GameDemoAnswers extends Component {
    static displayName = GameDemoAnswers.name;

    constructor(props) {
        super(props);

        this.state = {
            currentLinkee: "",
            linkeeSplit: [],
            LinkeeSplitWords: [],
            currentAnswer: "",
            currentSplitAnswer: [],
            newAnswerArray: [],
            linkeeIndex: -1,
            revealAll: false,
            revealedClue: false,
            playerStats: {},
            showIncorrect: false,
            validation: null,
            validationText: "",
            isMobile: false,
            width: 0,
            height: 0,
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

        this.handleNext = this.handleNext.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        this.retreiveLocalStorage();
        //
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    retreiveLocalStorage() {
        const todaysDate = this.getTodaysDate();
        this.getLinkee(todaysDate, false);
    }

    handleNext() {
        this.props.jump(10);
    }

    getTodaysDate() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    getTomorrowsDate() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const yyyy = tomorrow.getFullYear();
        let mm = tomorrow.getMonth() + 1; // Months start at 0!
        let dd = tomorrow.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    revealQuestion() {
        const newIndex = this.state.linkeeIndex + 1;
        this.setState({ linkeeIndex: newIndex });
        localStorage.setItem("linkeeIndex", newIndex.toString());
    }

    getLinkee(date, hasPlayedBefore = false) {
        const newLinkee = dummyLinkee;

        //new question answer tutorial
        const newLinkeeAnswer = this.props.answer;
        let newLinkeeAnswerArray = [];
        for (let i = 0; i < newLinkeeAnswer.length; i++) {
            newLinkeeAnswerArray.push(newLinkeeAnswer[i]);
        }
        this.setState({ newAnswerArray: newLinkeeAnswerArray })

        let newSplitLinkee = [];
        let arrayWords = newLinkee.linkee.split(" ");

        let arraySplit = [];
        let answerArray = [];

        for (let j = 0; j < arrayWords.length; j++) {
            let currWord = arrayWords[j].split("");
            arraySplit.push(currWord);
            answerArray.push([]);
        }

        for (let i = 0; i < newLinkee.linkee.length; i++) {
            newSplitLinkee.push(newLinkee.linkee[i]);
        }

        this.setState({
            currentLinkee: newLinkee,
            linkeeSplit: newSplitLinkee,
            LinkeeSplitWords: arraySplit,
            currentSplitAnswer: answerArray
        });
        return newLinkee;
    }

    //tryReveal = () => {
    //    let canReveal = this.state.linkeeIndex >= 3 ? true : false;
    //    if (canReveal && !this.state.revealedClue && !this.state.revealAll) {
    //        this.setState({ revealedClue: true });
    //        if (this.state.linkeeIndex < 4) {
    //            this.revealQuestion();
    //        }
    //    } else {

    //    }
    //}

    resize = () => {
        const mediaQuery = window.matchMedia("(min-width: 991px)");

        if (mediaQuery.matches) {
            this.setState({ isMobile: false });
        } else {
            this.setState({ isMobile: true });
        }
    }

    renderRows() {
        let finalArr = [], columns = [];
        var arrayLinkeeWords = this.state.LinkeeSplitWords;
        var arrayNewTutorial = this.state.newAnswerArray;

        arrayLinkeeWords.map((y, Yindex2) => {
            wordsRow2 = y.length;
            if (wordsRow2 > 9 & wordsRow2 != 0) {
                wordsRow2 = y.length;
                finalArr.push(<div className={styles.answerRow}>{columns}</div>);
                columns = [];
            }

            arrayNewTutorial.map((x, Xindex) => {
                const lastIndex = y.length - 1;
                const lastMap = arrayLinkeeWords.length - 1;
                columns.push(<div className={`${styles.answerBox} ${this.props.correct === true ? styles.correctAnswer : styles.wrongAnswer} ${this.state.isMobile && styles.smallerAns} ${Xindex === lastIndex && Yindex2 !== lastMap ? styles.space : ""} ${this.state.revealAll ? this.state.validation == true ? styles.correct : styles.incorrect : this.state.showIncorrect ? styles.incorrect : ""}`}>
                    <div className={`${styles.answerText} ${this.state.isMobile && styles.smallerAnsText}`}>{x}</div>
                </div>)
            })
        })
        finalArr.push(<div className={styles.answerRow}>{columns}</div>);

        return finalArr;
    }

    render() {
        return (
            <div className={`${styles.mainContainer} mainContainer`} style={{ height: this.state.height }}>
                <div className={`${styles.gameBox} ${styles.gameBoxAnswer} p-0`}>
                    <div className={styles.linkeeBox}>
                        <div className={styles.absBox}>
                            {
                                this.state.currentLinkee != null &&
                                <React.Fragment>
                                    <table className={`table ${styles.linksBox} ${styles.linksTable}`}>
                                            <tbody>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                            <tr className={`${styles.link} linkTableRow_0`}>
                                                <th>
                                                    <img src={one} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStrip tableStrip1`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr className={`${styles.link} linkTableRow_1`}>
                                                <th>
                                                    <img src={two} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"WAFFLE"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStrip tableStrip2`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr className={`${styles.link} linkTableRow_2`}>
                                                <th>
                                                    <img src={three} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"FRENCH"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStrip tableStrip3`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr className={`${styles.link} linkTableRow_3`}>
                                                <th>
                                                    <img src={four} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CURLY"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStripLast tableStrip4`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/*<div className={`${styles.clueBox}`}>*/}
                                    {/*    <div className={`${styles.showLinkButton} ${this.state.linkeeIndex >= 3 ? this.state.revealAll ? styles.disabled : "" : styles.disabled}`} onClick={this.tryReveal}>*/}
                                    {/*        <div className={styles.buttonText}>CLUE?</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    {this.state.currentLinkee != null &&
                        <div className={`${styles.answerSection} ${styles.answerSectionGuess}`}>
                            <div className={styles.answerContainer}>
                                <div className={styles.AnswerTitle}>What links the answers?</div>
                                {this.renderRows()}
                            </div>
                        </div>}

                    <div className={styles.keyboardBox} >
                        <div className={styles.row} style={{ visibility: 'hidden' }}>
                            {
                                keyboardMap.row1.map((x, index) => {
                                    return <div key={index + x + "_parent"} className={styles.key}>
                                        <div key={index + x} className={styles.letter}>{x}</div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={styles.spacer}></div>
                        <div className={styles.row} style={{ visibility: 'hidden' }}>
                            {
                                keyboardMap.row2.map((x, index) => {
                                    return <div key={index + x + "_parent"} className={styles.key}>
                                        <div key={index + x} className={styles.letter}>{x}</div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={styles.spacer}></div>
                        <div className={styles.row} style={{ visibility: 'hidden' }}>
                            {
                                keyboardMap.row3.map((x, index) => {
                                    return <div key={index + x + "_parent"} className={`${styles.key} ${x == "←" && styles.skipKey} ${x == "←" && styles.skipKey}`}>
                                        <div key={index + x} className={`${x == "←" ? styles.backspaceKey : styles.letter}`}>
                                            {x == "←" ? <img key={index + x + "_img"} src={Backspace} className={styles.backspaceImg} /> : x}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={`${styles.cardAnswer} card`}>
                            <div className={`${styles.cardBodyAnswer} card-body`}>
                                <p className={styles.pGraph2}>{this.props.correct === true ? "Correct!" : "Wrong!"} The link was:</p>
                                <p className={styles.pGraph3}>"Fries"</p>
                                <button className={styles.buttonAnswer} onClick={this.handleNext}>Next</button>
                                <img className={styles.borderArrow} src={bubbleArrow} />
                            </div>
                        </div>
                    </div>

                    <div className="row rowButtons justify-content-center">
                        <div className="col-5 ms-2 me-2 p-0">
                            <button disabled={true} style={{ visibility: 'hidden' }} className={`${styles.nextQuestionKey} ${styles.bottomButton}`}><span className={`${styles.noIdea}`}>No idea?</span> NEXT QUESTION</button>
                        </div>
                        <div className="col-5 ms-2 me-2 p-0">
                            <button disabled={true} style={{ visibility: 'hidden' }} type="submit" className={`${styles.submitKey} ${styles.bottomButton}`}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';

import styles from "components/Home.module.scss";
import one from "images/1.png";
import two from "images/2.png";
import three from "images/3.png";
import four from "images/4.png";

export class LinkTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            linkeeIndex: props.linkeeIndex,
            linkData: props.linkData,
            revealAll: props.revealAll,
            canReveal: props.linkeeIndex === props.linkData.index - 1 ? true : false,
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        this.setState({ linkeeIndex: nextProps.linkeeIndex, linkData: nextProps.linkData, revealAll: nextProps.revealAll }, () => {

            if (this.state.linkeeIndex === this.state.linkData.index - 1) {
                this.setState({ canReveal: true });
            }
        });

    }

    tryReveal = () => {
        if (this.state.canReveal && !this.state.revealAll) {
            this.setState({ canReveal: false });
            this.props.revealQuestion();
        } else {

        }
    }

    render() {
        return (
            <tr className={`${styles.link} linkTableRow_` + this.state.linkData.index + ``}>
                {/*<div className={styles.linkNumber}>*/}
                {this.state.linkData.index == 0 && <th><img src={one} className={`${styles.questionNumber}`} /></th>}
                {this.state.linkData.index == 1 && <th><img src={two} className={`${styles.questionNumber} ${this.state.linkeeIndex < 1 && styles.questionNumberFade}`} /></th>}
                {this.state.linkData.index == 2 && <th><img src={three} className={`${styles.questionNumber} ${this.state.linkeeIndex < 2 && styles.questionNumberFade}`} /></th>}
                {this.state.linkData.index == 3 && <th><img src={four} className={`${styles.questionNumber} ${this.state.linkeeIndex < 3 && styles.questionNumberFade}`} /></th>}
                {/*</div>*/}
                <td className={styles.linkQuestion}>
                    <div className={`${styles.linkText} ${this.state.revealAll ? styles.revealed : this.state.linkeeIndex >= this.state.linkData.index ? "" : styles.disabled}`}>{this.state.linkData.question}</div>
                    <div className={`${styles.linkAnswer} ${this.state.revealAll ? "" : styles.disabled}`}>{this.state.linkData.answer}</div>
                </td>
            </tr>
        );
    }
}

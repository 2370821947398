import React, { useState } from "react";
import { Steps, useSteps } from "react-step-builder";
import { LinkTable } from "components/LinkTable";
import { GameDemo } from "components/GameDemo";
import { GameDemoAnswers } from "components/GameDemoAnswers";

import one from "images/1.png";
import two from "images/2.png";
import three from "images/3.png";
import four from "images/4.png";
import bubbleArrow from "images/bubble-arrow.png";
import LinkeeBox from "images/linkeeBox.png";

import styles from "components/Tutorial.module.scss";

function Tutorial(props){

    let { next, prev, jump, current } = useSteps();
    const [answer, setAnswer] = useState("");

    const handleAnsChange = React.useCallback((newValue) => {
        setAnswer(newValue);
        hide();
    }, []);

    function hide() {
        let number = current + 1;
        props.hide(number);
    }

    function close() {
        props.close();
    }

    next = () => {
        let number = current + 1;

        jump(number);

        props.step(number);
    }

    return (
        <div className={`${styles.modalTutorial} modal-tutorial`}>
            <Steps>
                <div id="Step_1" className={`${styles.modalSteps} modal-steps`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.row} row`} style={{ height: props.height }}>
                        <div className={styles.rowCard}>
                            <div className={`${styles.card} card`}>
                                <div className={`${styles.cardBody} card-body`}>
                                    <p className={styles.pGraph1}>First time playing LINKEE?</p>
                                    <p className={styles.pGraph2}>Here's how it works...</p>
                                    <button className={`${styles.button} button`} onClick={next}>Next</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Step_2" className={`${styles.modalSteps2} modal-steps2`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.mainContainer} mainContainer row`} style={{ height: props.height }}>
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <React.Fragment>
                                        <table className={`table ${styles.linksTable}`}>
                                            <tbody className={`${styles.linksTableBody}`}>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                <tr className={`${styles.link} linkTableRow_0`}>
                                                    <th>
                                                        <img src={one} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkQuestion}>
                                                        <div className={`${styles.linkText}`}>What are Provolone, Swiss and Mozzarella?</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip1`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_1`}>
                                                    <th>
                                                        <img src={two} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkQuestion}>
                                                        <div className={`${styles.linkText}`}>A southern breakfast is fried chicken and what?</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip2`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_2`}>
                                                    <th>
                                                        <img src={three} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkQuestion}>
                                                        <div className={`${styles.linkText}`}>What nationality was the artist Claude Monet?</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip3`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_3`}>
                                                    <th>
                                                        <img src={four} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkQuestion}>
                                                        <div className={`${styles.linkText}`}>Putting rollers in your hair makes it go what?</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStripLast tableStrip4`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className={`${styles.row} row`}>
                                <div className={`${styles.card} card`}>
                                    <div className={`${styles.cardBody} card-body`}>
                                        <p className={styles.pGraph2}>You get given four questions, then a clue.</p>
                                        <p className={styles.pGraph2}>The aim is to guess the <span className={styles.pGraph1}>LINK between <br/> the answers </span>as early as possible.</p>
                                        <button className={`${styles.button} button`} onClick={next}>Next</button>
                                        <img className={styles.borderArrow} src={bubbleArrow} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Step_3" className={`${styles.modalStepsGuess} modal-stepsGuess`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.mainContainer} mainContainer row`} style={{ height: props.height }}>
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <React.Fragment>
                                        <table className={`table ${styles.linksTable}`}>
                                            <tbody className={`${styles.linksTableBody}`}>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                <tr className={`${styles.link} linkTableRow_0`}>
                                                    <th>
                                                        <img src={one} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip1`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_1`}>
                                                    <th>
                                                        <img src={two} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText}`}></div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip2`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_2`}>
                                                    <th>
                                                        <img src={three} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText}`}></div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip3`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_3`}>
                                                    <th>
                                                        <img src={four} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText}`}></div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStripLast tableStrip4`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className={`${styles.row} row mt-3`}>
                                <div className={`${styles.card} card`}>
                                    <div className={`${styles.cardBody} card-body`}>
                                        <p className={styles.pGraph2}>What links the answers?</p>
                                        <button className={`${styles.button} button`} onClick={next}>Guess</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Step_4" className={`${styles.modalStepsGuess} modal-stepsGuess`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.mainContainer} mainContainer row`} style={{ height: props.height }}>
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <React.Fragment>
                                        <table className={`table ${styles.linksTable}`}>
                                            <tbody className={`${styles.linksTableBody}`}>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                <tr className={`${styles.link} linkTableRow_0`}>
                                                    <th>
                                                        <img src={one} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip1`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_1`}>
                                                    <th>
                                                        <img src={two} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"WAFFLE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip2`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_2`}>
                                                    <th>
                                                        <img src={three} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText}`}></div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip3`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_3`}>
                                                    <th>
                                                        <img src={four} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText}`}></div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStripLast tableStrip4`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className={`${styles.row} row mt-3`}>
                                <div className={`${styles.card} card`}>
                                    <div className={`${styles.cardBody} card-body`}>
                                        <p className={styles.pGraph2}>What links the answers?</p>
                                        <button className={`${styles.button} button`} onClick={next}>Guess</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Step_5" className={`${styles.modalStepsGuess} modal-stepsGuess`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.mainContainer} mainContainer row`} style={{ height: props.height }}>
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <React.Fragment>
                                        <table className={`table ${styles.linksTable}`}>
                                            <tbody className={`${styles.linksTableBody}`}>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                <tr className={`${styles.link} linkTableRow_0`}>
                                                    <th>
                                                        <img src={one} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip1`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_1`}>
                                                    <th>
                                                        <img src={two} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"WAFFLE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip2`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_2`}>
                                                    <th>
                                                        <img src={three} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"FRENCH"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip3`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_3`}>
                                                    <th>
                                                        <img src={four} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText}`}></div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStripLast tableStrip4`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className={`${styles.row} row mt-3`}>
                                <div className={`${styles.card} card`}>
                                    <div className={`${styles.cardBody} card-body`}>
                                        <p className={styles.pGraph2}>What links the answers?</p>
                                        <button className={`${styles.button} button`} onClick={next}>Guess</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Step_6" className={`${styles.modalStepsGuess} modal-stepsGuess`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.mainContainer} mainContainer row`} style={{ height: props.height }}>
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <React.Fragment>
                                        <table className={`table ${styles.linksTable}`}>
                                            <tbody className={`${styles.linksTableBody}`}>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                <tr className={`${styles.link} linkTableRow_0`}>
                                                    <th>
                                                        <img src={one} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip1`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_1`}>
                                                    <th>
                                                        <img src={two} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"WAFFLE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip2`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_2`}>
                                                    <th>
                                                        <img src={three} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"FRENCH"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip3`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_3`}>
                                                    <th>
                                                        <img src={four} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CURLY"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStripLast tableStrip4`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className={`${styles.row} row mt-3`}>
                                <div className={`${styles.card} card`}>
                                    <div className={`${styles.cardBody} card-body`}>
                                        <p className={styles.pGraph2}>What links the answers?</p>
                                        <button className={`${styles.button} button`} onClick={next}>Guess</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Tutorial gamebox*/}
                <div id="Step_7" className={`${styles.modalSteps3} modal-steps3`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <GameDemo jump={jump} answer={handleAnsChange} />
                </div>
                <div id="Step_8" className={`${styles.modalSteps4} modal-steps4 wrong`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <GameDemoAnswers correct={false} answer={answer} jump={jump} />
                </div>
                <div id="Step_9" className={`${styles.modalSteps4} modal-steps4 correct`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <GameDemoAnswers correct={true} answer={answer} jump={jump} />
                </div>
                <div id="Step_10" className={`${styles.modalSteps2} modal-steps2`}>
                    <header className={styles.headerTutorial}>
                        <button className={`${styles.headerButton} button`} onClick={close}>Skip Tutorial &rsaquo;</button>
                    </header>
                    <div className={`${styles.mainContainer} mainContainer row`} style={{ height: props.height }}>
                        <div className={styles.gameBox}>
                            <div className={styles.linkeeBox}>
                                <div className={styles.absBox}>
                                    <React.Fragment>
                                        <table className={`table ${styles.linksTable}`}>
                                            <tbody className={`${styles.linksTableBody}`}>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                                <tr className={`${styles.link} linkTableRow_0`}>
                                                    <th>
                                                        <img src={one} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip1`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_1`}>
                                                    <th>
                                                        <img src={two} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"WAFFLE"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip2`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_2`}>
                                                    <th>
                                                        <img src={three} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"FRENCH"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStrip tableStrip3`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                                <tr className={`${styles.link} linkTableRow_3`}>
                                                    <th>
                                                        <img src={four} className={`${styles.questionNumber}`} />
                                                    </th>
                                                    <td className={styles.linkAnswer}>
                                                        <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CURLY"</div>
                                                    </td>
                                                </tr>
                                                <tr className={`tableStripLast tableStrip4`}>
                                                    <th></th>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </React.Fragment>
                                </div>
                            </div>
                            <div className={`${styles.row} row`}>
                                <div className={`${styles.card} card`}>
                                    <div className={`${styles.cardBody} card-body`}>
                                        <p className={styles.pGraph2}>The quicker you guess the Linkee, the better!</p>
                                        <button className={`${styles.button} button`} onClick={close}>Finish</button>
                                        <img className={styles.borderArrow} src={bubbleArrow} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Steps>
        </div>
    );
}
export default Tutorial;
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
const instance = axios.create();
const apiUrl = process.env.REACT_APP_API_URL;

const insertNewPlay = (options) => {
    instance.post(apiUrl + "plays/insert-new-play", {
        UserId: GetUserId(),
    }).then((res) => {
        console.log(res);
    }).catch((err) => {
        console.log(err.response);
    });
}

const updatePlayFinished = (options) => {
    instance.post(apiUrl + "plays/update-play-finished", {
        Play: {
            Correct: options.correct,
            LinksUsed: options.linkeeIndex + 1,
        },
        UserId: GetUserId(),
    }).then((res) => {
        console.log(res);
    }).catch((err) => {
        console.log(err);
    });
}

const countNewClick = (clickType) => {
    instance.post(apiUrl + "clicks/record-click", {
        ClickType: clickType,
    }).then((res) => {
        console.log(res);
    }).catch((err) => {
        console.log(err);
    });
}

const countNewShare = () => {
    const userId = GetUserId();
    instance.post(apiUrl + "clicks/record-share", {
        UserId: userId,
    }).then((res) => {
        console.log(res);
    }).catch((err) => {
        console.log(err);
    });
}

function getCountryCode() {
    return instance.get(apiUrl + "plays/get-country-code", {
        
    }).then((res) => {
        console.log(res);
        return res.data;
    }).catch((err) => {
        console.log(err);
    });
}

function GetUserId() {
    let userId = localStorage.getItem("UserId");
    if (userId == null) {
        userId = uuidv4();
        localStorage.setItem("UserId", userId);
    }
    return userId;
}



export default {
    insertNewPlay,
    updatePlayFinished,
    countNewClick,
    countNewShare,
    getCountryCode,
}
import React, { Component, useEffect, useState } from 'react';
import Countdown from 'react-countdown';

import styles from "components/Home.module.scss";

function CountdownTimer() {
    const [countdownTime, setCountdownTime] = useState(new Date().getTime());

    const countdownTimer = () => {
        const todaysDate = new Date();
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        var date1_ms = todaysDate.getTime();
        var date2_ms = tomorrow.getTime();

        var difference_ms = date2_ms - date1_ms;

        setCountdownTime(difference_ms);
    }

    useEffect(() => {
        countdownTimer();
    });

    const timeRenderer = ({ hours, minutes, seconds, }) => {
        return <div className={styles.time}>{hours}h {minutes}m {seconds}s</div>;
    }

    return (
        <div className={styles.countdownSection}>
            <h4 className={styles.countdownTitle}>Next Linkee in: </h4>
            <div className={styles.timerSection}>
                <Countdown date={Date.now() + countdownTime} renderer={timeRenderer}/>
            </div>
        </div>
    )
}
export default CountdownTimer;

//export class Link extends Component {

//    constructor(props) {
//        super(props);
//        this.state = {
//        };
//    }

//    componentDidMount() {

//    }

//    componentWillReceiveProps(nextProps) {

//    }

//    render() {
//        return (
//        );
//    }
//}

const firePageView = (pageName) => {

   
}

export const fireBuyClick = (options) => {
    window.fbq('trackCustom', "BuyLinkeeClick", {
        date: new Date(),
        locale: options.locale,
    });

    window.gtag('event', 'buy_linkee_click', {
        date: new Date(),
        locale: options.locale,
    });
}

export const fireLinkeeCompletion = async (options = {}) => {
    window.fbq('trackCustom', "LinkeeCompletion", {
        date: new Date(),
        wasCorrect: options.correct,
        locale: options.locale,
    });

    window.gtag('event', 'linkee_completion', {
        date: new Date(),
        wasCorrect: options.correct,
        locale: options.locale,
    });
}
import React, { Component } from 'react';

import styles from "components/Home.module.scss";

export class Stat extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.title,
            totalPlayed: props.totalPlayed,
            statPlayed: props.statPlayed,
            percentage: 0,
            clue: props.clue
        };
    }

    componentDidMount() {
        let myPercentage = this.setPercentage();
        this.setState({ percentage: myPercentage });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ totalPlayed: nextProps.totalPlayed, statPlayed: nextProps.statPlayed }, () => {

            let myPercentage = this.setPercentage();
            this.setState({ percentage: myPercentage });

        });

    }

    setPercentage() {
        return (100 * this.state.statPlayed) / this.state.totalPlayed;
    }

    render() {
        return (
            <tr className={styles.stat}>
                <td className={styles.statTitle}>{this.state.title}</td>
                <td colSpan="2" className={styles.statBarContainer}>
                    <div className={`${styles.statBar} ${this.props.clue == true ? styles.statBarClue : ""} ${this.state.percentage == 0 ? styles.percentZero : ""} ${this.props.color == "green" ? styles.green : this.props.color == "#FD4A5C" ? styles.red : this.props.color == "purple" ? styles.purple : this.props.color == "blue" ? styles.blue : ""}`} style={{ width: `${this.state.percentage}%` }}>
                        {this.state.statPlayed}
                    </div>
                </td>
            </tr>
        );
    }
}

import React, { Component } from 'react';
import "animate.css";

import styles from "components/GameDemo.module.scss";

import content from "constants/content";
import Backspace from "images/backspace.png";
import one from "images/1.png";
import two from "images/2.png";
import three from "images/3.png";
import four from "images/4.png";
import LinkeeBox from "images/linkeeBox.png";

import "styles/scss/main.scss";

const dummyLinkee = {
    id: 1,
    date: "10/06/2022",
    links: [
        {
            index: 0,
            question: "\"What are Provolone, Swiss and Mozzarella?",
            answer: "Cheese"
        },
        {
            index: 1,
            question: "A southern breakfast is fried chicken and what?",
            answer: "Waffle"
        },
        {
            index: 2,
            question: "What nationality was the artist Claude Monet?",
            answer: "French"
        },
        {
            index: 3,
            question: "Putting rollers in your hair makes it go what?",
            answer: "Curly"
        }
    ],
    clue: "There's only one real McCains",
    linkee: "Fries"
};

const keyboardMap = {
    row1: ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    row2: ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
    row3: ["Z", "X", "C", "V", "B", "N", "M", "←"]
}

let wordsRow2 = 0;

export class GameDemo extends Component {
    static displayName = GameDemo.name;

    constructor(props) {
        super(props);

        this.state = {
            currentLinkee: "",
            linkeeSplit: [],
            LinkeeSplitWords: [],
            currentAnswer: "",
            currentSplitAnswer: [],
            linkeeIndex: -1,
            revealAll: false,
            revealedClue: false,
            showIncorrect: false,
            validation: null,
            validationText: "",
            isMobile: false,
            width: 0,
            height: 0,
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize);
        this.resize();
        this.retreiveLocalStorage();
        document.addEventListener("keydown", this.keydownHandler);
        //
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    retreiveLocalStorage() {
        const todaysDate = this.getTodaysDate();
        this.getLinkee(todaysDate, false);
    }

    getTodaysDate() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    getTomorrowsDate() {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const yyyy = tomorrow.getFullYear();
        let mm = tomorrow.getMonth() + 1; // Months start at 0!
        let dd = tomorrow.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    revealQuestion() {
        const newIndex = this.state.linkeeIndex + 1;
        this.setState({ linkeeIndex: newIndex });
        localStorage.setItem("linkeeIndex", newIndex.toString());
    }

    keydownHandler = (event) => {
        let currentAnswer = this.state.currentAnswer;
        if (event.keyCode >= 65 && event.keyCode <= 90 || event.keyCode >= 48 && event.keyCode <= 57) {
            if (currentAnswer.length != this.state.currentLinkee.linkee.length) {
                this.addLetterToAnswer(event.key);
            }
            this.setState({ showIncorrect: false });
        } else if (event.keyCode == 8) {
            this.removeLetterFromAnswer();
            this.setState({ showIncorrect: false });
        } else if (event.keyCode == 13) {
            this.submitAnswer();
        }
    }

    handleInput = (key) => {
        let currentAnswer = this.state.currentAnswer;
        if (key == "←") {
            this.removeLetterFromAnswer();
            this.setState({ showIncorrect: false });
        }
        else if (key == "SKIP") {
            if (this.state.linkeeIndex < 3) {
                this.revealQuestion();
            }
        }
        else if (key == "SUBMIT") {
            this.submitAnswer();
        } else {
            if (currentAnswer.length != this.state.currentLinkee.linkee.length) {
                this.addLetterToAnswer(key);
            }
            this.setState({ showIncorrect: false });
        }
    }

    addLetterToAnswer(letter) {
        let currentAnswer = this.state.currentAnswer;

        let currentLinkee = this.state.currentLinkee.linkee;

        let indices = [];
        for (let i = 0; i < currentLinkee.length; i++) {
            if (currentLinkee[i] === " ") indices.push(i);
        }

        for (let j = 0; j < indices.length; j++) {
            if (currentAnswer.length === (indices[j])) {
                currentAnswer += " ";
            }
        }

        if (typeof letter == "object") {
            for (let i = 0; i < letter.length; i++) {
                currentAnswer += letter[i].toUpperCase();
            }
        } else {

            currentAnswer += letter.toUpperCase();
        }

        let splitAnswer = this.state.currentSplitAnswer;

        splitAnswer = splitAnswer.map((x) => []);

        let splitLinkee = this.state.LinkeeSplitWords;

        let letters = currentAnswer.split("");
        letters = letters.filter((x) => x != " ");
        console.log(letters);
        let letterIndex = 0;

        for (let x = 0; x < splitLinkee.length; x++) {
            let currWord = splitAnswer[x];
            let actualWord = splitLinkee[x];

            if (currWord.length == actualWord.length) {

            } else {
                for (let y = 0; y < actualWord.length; y++) {
                    if (letters[letterIndex]) {
                        console.log(letterIndex);
                        console.log(x);
                        console.log(y);
                        splitAnswer[x][y] = letters[letterIndex];
                        console.log(splitAnswer);
                        letterIndex++;
                    }
                }
            }
        }

        this.setState({
            currentAnswer,
            currentSplitAnswer: splitAnswer
        });
    }

    removeLetterFromAnswer() {
        let currentAnswer = this.state.currentAnswer;
        currentAnswer = currentAnswer.slice(0, -1);

        console.log(this.state.currentSplitAnswer);
        let newAnswer = this.state.currentSplitAnswer;
        console.log(newAnswer);
        for (let x = newAnswer.length - 1; x >= 0; x--) {
            let ansWord = newAnswer[x];

            console.log("ANSWORD: " + ansWord);
            if (ansWord.length > 0) {
                console.log("minus");
                console.log(newAnswer[x]);
                newAnswer[x].pop();
                console.log(newAnswer[x]);

                break;
            }
        }

        console.log(newAnswer);

        this.setState({ currentAnswer, currentSplitAnswer: newAnswer });
    }

    getLinkee(date, hasPlayedBefore = false) {
        const newLinkee = dummyLinkee;
        let newSplitLinkee = [];
        let arrayWords = newLinkee.linkee.split(" ");

        let arraySplit = [];
        let answerArray = [];

        for (let j = 0; j < arrayWords.length; j++) {
            let currWord = arrayWords[j].split("");
            arraySplit.push(currWord);
            answerArray.push([]);
        }

        for (let i = 0; i < newLinkee.linkee.length; i++) {
            newSplitLinkee.push(newLinkee.linkee[i]);
        }

        this.setState({ currentLinkee: newLinkee, linkeeSplit: newSplitLinkee, LinkeeSplitWords: arraySplit, currentSplitAnswer: answerArray }, () => {
            if (hasPlayedBefore) {
                let split = newLinkee.linkee.split("");
                this.addLetterToAnswer(split);
            }
        });
        return newLinkee;
    }

    getLinkeeByDate(date) {
        let todaysLinkee;
        content.forEach((x) => {
            if (x.date == date) {
                todaysLinkee = x;
            }
        });
        return todaysLinkee;
    }

    submitAnswer() {
        const currentAnswer = this.state.currentAnswer.toLowerCase();
        const actualAnswer = this.state.currentLinkee.linkee.toLowerCase();
        if (currentAnswer.length == actualAnswer.length) {
            if (currentAnswer === actualAnswer) {
                //alert("right");
                this.props.jump(9);
            } else {
                //alert("wrong");
                this.props.jump(8);
            }

            this.handleAnsChange();
        } else {
            this.animateCSS("#answerContainer", "shakeX");
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            if (node) {
                node.classList.add(`${prefix}animated`, animationName);

                // When the animation ends, we clean the classes and resolve the Promise
                function handleAnimationEnd(event) {
                    event.stopPropagation();
                    node.classList.remove(`${prefix}animated`, animationName);
                    resolve('Animation ended');
                }

                node.addEventListener('animationend', handleAnimationEnd, { once: true });
            }
        });

    handleAnsChange = () => {
        var ans = this.state.currentAnswer;
        this.props.answer(ans);
    }

    //tryReveal = () => {
    //    let canReveal = this.state.linkeeIndex >= 3 ? true : false;
    //    if (canReveal && !this.state.revealedClue && !this.state.revealAll) {
    //        this.setState({ revealedClue: true });
    //        if (this.state.linkeeIndex < 4) {
    //            this.revealQuestion();
    //        }
    //    } else {

    //    }
    //}

    resize = () => {
        const mediaQuery = window.matchMedia("(min-width: 991px)");

        if (mediaQuery.matches) {
            this.setState({ isMobile: false });
        } else {
            this.setState({ isMobile: true });
        }
    }

    renderRows() {
        let finalArr = [], columns = [];
        var arrayLinkeeWords = this.state.LinkeeSplitWords;

        arrayLinkeeWords.map((y, Yindex2) => {
            wordsRow2 = y.length;
            if (wordsRow2 > 9 & wordsRow2 != 0) {
                wordsRow2 = y.length;
                finalArr.push(<div className={styles.answerRow}>{columns}</div>);
                columns = [];
            }

            y.map((x, Xindex) => {
                const lastIndex = y.length - 1;
                const lastMap = arrayLinkeeWords.length - 1;
                columns.push(<div className={`${styles.answerBox} ${this.state.isMobile && styles.smallerAns} ${Xindex === lastIndex && Yindex2 !== lastMap ? styles.space : ""} ${this.state.revealAll ? this.state.validation == true ? styles.correct : styles.incorrect : this.state.showIncorrect ? styles.incorrect : ""}`}>
                    <div className={`${styles.answerText} ${this.state.isMobile && styles.smallerAnsText}`}>{this.state.currentSplitAnswer[Yindex2][Xindex]}</div>
                </div>)
            })
        })
        finalArr.push(<div className={styles.answerRow}>{columns}</div>);

        return finalArr;
    }

    render() {
        return (
            <div className={`${styles.mainContainer} mainContainer`} style={{ height: this.state.height }}>
                <div className={`${styles.gameBox} p-0`}>
                    <div className={styles.linkeeBox}>
                        <div className={styles.absBox}>
                            {
                                this.state.currentLinkee != null &&
                                <React.Fragment>
                                    <table className={`table ${styles.linksBox} ${styles.linksTable}`}>
                                            <tbody>
                                                <img src={LinkeeBox} style={{ position: "absolute", width: "100%", height: "100%" }} />
                                            <tr className={`${styles.link} linkTableRow_0`}>
                                                <th>
                                                    <img src={one} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CHEESE"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStrip tableStrip1`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr className={`${styles.link} linkTableRow_1`}>
                                                <th>
                                                    <img src={two} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"WAFFLE"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStrip tableStrip2`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr className={`${styles.link} linkTableRow_2`}>
                                                <th>
                                                    <img src={three} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"FRENCH"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStrip tableStrip3`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                            <tr className={`${styles.link} linkTableRow_3`}>
                                                <th>
                                                    <img src={four} className={`${styles.questionNumber}`} />
                                                </th>
                                                <td className={styles.linkAnswer}>
                                                    <div className={`${styles.linkText} ${styles.linkTextRed}`}><span className={styles.answerText}>Answer: </span>"CURLY"</div>
                                                </td>
                                            </tr>
                                            <tr className={`tableStripLast tableStrip4`}>
                                                <th></th>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/*<div className={`${styles.clueBox}`}>*/}
                                    {/*    <div className={`${styles.showLinkButton} ${this.state.linkeeIndex >= 3 ? this.state.revealAll ? styles.disabled : "" : styles.disabled}`} onClick={this.tryReveal}>*/}
                                    {/*        <div className={styles.buttonText}>CLUE?</div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    {this.state.currentLinkee != null &&
                        <div className={styles.answerSection}>
                            <div id="answerContainer" className={styles.answerContainer}>
                                <div className={styles.AnswerTitle}>What links the answers?</div>
                                {this.renderRows()}
                            </div>
                        </div>}

                    <div className={styles.keyboardBox}>
                        <div className={styles.row}>
                            {
                                keyboardMap.row1.map((x, index) => {
                                    return <div key={index + x + "_parent"} className={styles.key} onClick={() => this.handleInput(x)}>
                                        <div key={index + x} className={styles.letter}>{x}</div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={styles.spacer}></div>
                        <div className={styles.row}>
                            {
                                keyboardMap.row2.map((x, index) => {
                                    return <div key={index + x + "_parent"} className={styles.key} onClick={() => this.handleInput(x)}>
                                        <div key={index + x} className={styles.letter}>{x}</div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={styles.spacer}></div>
                        <div className={styles.row}>
                            {
                                keyboardMap.row3.map((x, index) => {
                                    return <div key={index + x + "_parent"} className={`${styles.key} ${x == "←" && styles.skipKey} ${x == "←" && styles.skipKey}`} onClick={() => this.handleInput(x)}>
                                        <div key={index + x} className={`${x == "←" ? styles.backspaceKey : styles.letter}`}>
                                            {x == "←" ? <img key={index + x + "_img"} src={Backspace} className={styles.backspaceImg} /> : x}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-5 ms-2 me-2 p-0">
                            <button disabled={true} className={`${styles.nextQuestionKey} ${styles.bottomButton}`} onClick={() => this.state.linkeeIndex < 3 && this.revealQuestion()}><span className={`${styles.noIdea}`}>
                                No idea?</span> NEXT QUESTION
                                <div className={styles.overlay}></div>
                            </button>
                        </div>
                        <div className="col-5 ms-2 me-2 p-0">
                            <button type="submit" className={`${styles.submitKey} ${styles.bottomButton}`} onClick={() => this.submitAnswer()}>SUBMIT</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
